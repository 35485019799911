<template>
  <div class="main-container">
    <div class="search">
      <div class="clear">
        <div class="title f-left">讲师筛选</div>
        <div class="search-warp">
          <div class="search-box">
            <input
              class="search-input"
              type="text"
              v-model="search.teacher_name"
              @keydown.enter="getTeacher"
            />
            <span class="search-btn">
              <img
                class="search-btn-img"
                src="@/assets/images/icon_head_search.png"
                alt="搜索"
                @click="getTeacher"
              />
            </span>
          </div>
          <a @click="getTeacher">搜索</a>
        </div>
      </div>
      <div class="item">
        <label>上课时间：</label>
        <span>日期：</span>
        <el-input
          placeholder="选择日期"
          v-model="search.date"
          readonly
          class="date"
          @click.native="showSelectDate=true"
        ></el-input>
        <el-time-picker
          v-model="search.beginTime"
          placeholder="开始时间"
          format="HH:mm"
          value-format="HH:mm"
          :disabled="search.date==''"
          @change="timeChange"
        ></el-time-picker>
        <span>-</span>
        <el-time-picker
          v-model="search.endTime"
          placeholder="结束时间"
          format="HH:mm"
          value-format="HH:mm"
          :disabled="search.date==''"
          :minTime="search.beginTime"
          @change="timeChange"
        ></el-time-picker>
      </div>
      <div class="item clear">
        <label>老师类型：</label>
        <el-radio-group v-model="search.level" @change="getTeacher">
          <el-radio
            :label="level.level_id"
            v-for="level in levels"
            :key="level.level_id"
          >{{level.level_name}}</el-radio>
        </el-radio-group>
      </div>
      <div class="item">
        <label>我只看：</label>
        <el-checkbox v-model="search.is_collect" @change="getTeacher">已关注</el-checkbox>
        <el-checkbox v-model="search.is_attended" @change="getTeacher">上过课的</el-checkbox>
      </div>
      <div class="item">
        <label>讲师性别：</label>
        <el-radio-group v-model="search.sex" @change="getTeacher">
          <el-radio :label="-1">全部</el-radio>
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
      </div>
      <div class="item" v-for="attr in attrs" :key="attr.attr_id">
        <label :title="attr.attr_name">{{attr.attr_name}}：</label>
        <el-radio-group v-model="search.attr[attr.attr_id]" @change="getTeacher">
          <el-radio
            :label="item.attr_val_id"
            v-for="item in attr.child"
            :key="item.attr_val_id"
          >{{item.attr_val_name}}</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="main clear">
      <div class="teacher f-left" v-if="teachers.length>0">
        <div class="clear">
          <div
            class="item clear"
            :class="{'current':teacherId==teacher.teacher_id}"
            v-for="teacher in teachers"
            :key="teacher.teacher_id"
            @click="changeTeacher(teacher.teacher_id)"
          >
            <img class="avatar f-left" :src="teacher.avatar" />
            <div class="f-left">
              <div class="title">{{teacher.real_name}}</div>
              <div class="intro">
                <span>{{teacher.sex|sex}}</span>
                <span>{{teacher.age}}岁</span>
                <span>{{teacher.teach_age}}年教龄</span>
              </div>
              <div class="bottom">
                <router-link :to="{name:'OtoTeacherShow',params:{id:teacher.teacher_id}}">详情</router-link>
                <a v-if="teacher.is_collect==0" @click.stop="toggleCollect(teacher)">收藏</a>
                <a v-else @click.stop="toggleCollect(teacher)">取消收藏</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pager clear">
          <a class="left-2" :disabled="page<=1" @click="goToPage(1)"></a>
          <a class="left" :disabled="page<=1" @click="goToPage(page-1)"></a>
          <span>{{page}}/{{totalPage}}</span>
          <a class="right" :disabled="page>=totalPage" @click="goToPage(page+1)"></a>
          <a class="right-2" :disabled="page>=totalPage" @click="goToPage(totalPage)"></a>
        </div>
        <div class="jump">
          <span>跳转至</span>
          <el-input v-model="jumpPage"></el-input>
          <span>页</span>
          <a @click="jump">跳转</a>
        </div>
      </div>
      <div class="teacher empty center f-left" v-else>
        <img src="@/assets/images/oto/empty.png" />
        <div>该时段暂无老师～</div>
      </div>
      <OtoScheduleTable :teacherId="teacherId.toString()" :is_next="is_next" class="f-left"></OtoScheduleTable>
    </div>
    <el-dialog :visible.sync="showSelectDate" width="500" center :show-close="false">
      <table class="table-date">
        <thead>
          <tr>
            <th></th>
            <th>一</th>
            <th>二</th>
            <th>三</th>
            <th>四</th>
            <th>五</th>
            <th>六</th>
            <th>日</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>本周</td>
            <td
              :class="{'disabled':date.format('yyyy-MM-dd')<today,'today':date.format('yyyy-MM-dd')==today,'date':date.format('yyyy-MM-dd')>=today,'selected':date.format('yyyy-MM-dd')==search.date}"
              v-for="(date,index) in thisWeekDates"
              :key="index"
              @click="selectDate(date.format('yyyy-MM-dd'),0)"
            >{{date.format("MM-dd")}}</td>
          </tr>
          <tr>
            <td>下周</td>
            <td
              :class="{'disabled':date.format('yyyy-MM-dd')<today,'today':date.format('yyyy-MM-dd')==today,'date':date.format('yyyy-MM-dd')>=today,'selected':date.format('yyyy-MM-dd')==search.date}"
              v-for="(date,index) in nextWeekDates"
              :key="index"
              @click="selectDate(date.format('yyyy-MM-dd'),1)"
            >{{date.format("MM-dd")}}</td>
          </tr>
        </tbody>
      </table>
      <span slot="footer" class="dialog-footer">
        <a class="lnk-today" @click="selectDate(today)">今天</a>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import OtoScheduleTable from "../../components/OtoScheduleTable.vue";

export default {
  name: "Oto",
  components: {
    OtoScheduleTable
  },
  data() {
    return {
      search: {
        teacher_name: "",
        date: "",
        level: 0,
        attr: {},
        is_collect: false,
        is_attended: false,
        beginTime: "",
        endTime: "",
        sex: -1
      },
      levels: [],
      attrs: [],
      showSelectDate: false,
      today: new Date().format("yyyy-MM-dd"),
      thisWeekDates: [],
      nextWeekDates: [],
      teachers: [],
      teacherId: 0,
      timer: 0,
      page: 1,
      limit: 5,
      total: 0,
      totalPage: 0,
      jumpPage: "",
      is_next: 0
    };
  },
  async created() {
    let data = await this.$http.get("/api/app/otoCourseOptions");
    this.levels = data.otoTeacherLevel;
    this.levels.unshift({
      level_id: 0,
      level_name: "全部"
    });
    this.attrs = data.attrList || [];
    this.attrs.forEach(a => {
      this.$set(this.search.attr, a.attr_id, 0);
      a.child.unshift({
        attr_val_id: 0,
        attr_val_name: "全部"
      });
    });
    this.thisWeekDates = this.getDates(0);
    this.nextWeekDates = this.getDates(1);
    await this.getTeacher();
  },
  methods: {
    getDates(isNext) {
      let date = new Date();
      let weekDay = date.getDay();
      let beginDate = [];
      if (weekDay == 0) {
        beginDate = date.getDate() - 6;
      } else {
        beginDate = date.getDate() - (weekDay - 1);
      }
      if (isNext == 1) {
        beginDate = beginDate + 7;
      }
      let dates = [];
      for (let i = 0; i <= 6; i++) {
        let date = new Date();
        date.setDate(beginDate + i);
        dates.push(date);
      }
      return dates;
    },
    async selectDate(date, is_next) {
      if (date < this.today) {
        return;
      }
      this.is_next = is_next;
      this.search.date = date;
      this.showSelectDate = false;
      await this.getTeacher();
    },
    async timeChange() {
      await this.getTeacher();
    },
    async getTeacher() {
      let params = {
        level_id: this.search.level,
        is_collect: this.search.is_collect ? 1 : 0,
        is_attended: this.search.is_attended ? 1 : 0,
        teacher_name: this.search.teacher_name,
        page: this.page,
        limit: this.limit
      };
      if (this.search.date != "") {
        params.start_time = this.search.date;
        params.end_time = this.search.date;
        if (this.search.beginTime) {
          params.start_time += " " + this.search.beginTime;
        }
        if (this.search.endTime) {
          params.end_time += " " + this.search.endTime;
        } else {
          params.end_time += " 23:59";
        }
      }
      if (this.search.sex != -1) {
        params.sex = this.search.sex;
      }
      let attrIds = [];
      for (let key in this.search.attr) {
        let valId = this.search.attr[key];
        if (valId != 0) {
          attrIds.push(valId);
        }
      }
      params.attr_val_id = attrIds.join(",");
      let data = await this.$http.get("/api/app/otoCourse", params);
      this.teachers = data.list;
      this.teacherId = 0;
      if (data.list.length > 0) {
        this.changeTeacher(data.list[0].teacher_id);
      }
      let totalPage = parseInt(data.total / this.limit);
      if (data.total % this.limit > 0) {
        totalPage++;
      }
      this.totalPage = totalPage;
    },
    async changeTeacher(id) {
      this.teacherId = id;
    },
    async toggleCollect(teacher) {
      await this.$http.get(
        "/api/app/teacher/collect/" + teacher.teacher_id,
        {},
        true
      );
      let is_collect = teacher.is_collect == 0 ? 1 : 0;
      if (is_collect == 1) {
        await this.$success("收藏成功");
      } else {
        await this.$success("取消收藏成功");
      }
      teacher.is_collect = is_collect;
    },
    async goToPage(page) {
      if (page <= 0 || page > this.totalPage || page == this.page) {
        return;
      }
      this.page = page;
      await this.getTeacher();
    },
    async jump() {
      if (!/[0-9]+/.test(this.jumpPage)) {
        this.$info("请输入正整数");
        return;
      }
      if (this.jumpPage > this.totalPage) {
        this.$info("不得大于" + this.totalPage);
        return;
      }
      if (this.jumpPage < 1) {
        this.$info("必须大于0");
        return;
      }
      this.page = this.jumpPage;
      await this.getTeacher();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/oto.scss";
</style>
